<template>
  <div class="changeContainer">
    <headPageNew></headPageNew>
    <!-- 头部区域 -->
    <div class="change_main">
      <el-card class="box-card" style="width: 100%;">
        <el-steps :active="active" align-center>
          <el-step title="身份验证"></el-step>
          <el-step title="更改密码"></el-step>
          <el-step title="更改成功"></el-step>
        </el-steps>
        <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="120px" class="change_main_form">
          <el-form-item label="手机号" v-if="active == 1" prop="mobilePhone">
            <el-input v-model="ruleForm.mobilePhone" :disabled="forgetPasswordType == 2"
                      @focus="clearValid('mobilePhone')"
                      @input="filter($event, 'mobilePhone')"
                      placeholder="请输入您的手机号码"></el-input>
          </el-form-item>
          <el-form-item label="验证码" v-if="active == 1" prop="msgCode">
            <el-input v-model="ruleForm.msgCode" @focus="clearValid('msgCode')" @input="filter($event, 'msgCode')"
                      style="width: 250px; margin-right: 17px;" placeholder="请输入验证码">
            </el-input>
            <el-button :disabled="!showSend" type="primary" @click="getMsgCode" style="width:112px;">
              {{showSend?"获取验证码":totalTime}}
            </el-button>
          </el-form-item>
          <el-form-item label="新密码" v-if="active == 2" prop="passwordNew">
            <el-input type="password" @focus="clearValid('passwordNew')" v-model="ruleForm.passwordNew"
                      placeholder="请输入8-20位由字母、数字、特殊符号组成的密码"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" v-if="active == 2" prop="passwordOk">
            <el-input type="password" @focus="clearValid('passwordOk')" v-model="ruleForm.passwordOk"
                      placeholder="请再次输入新密码"></el-input>
          </el-form-item>
          <el-form-item class="button-group">
            <el-button v-if="active == 1" type="primary" @click="first" style="width: 100%">下一步<i
                class="el-icon-d-arrow-right el-icon--right"></i></el-button>
            <el-button v-if="active == 2" type="primary" @click="second" style="width: 100%" icon="el-icon-check">更改密码
            </el-button>
          </el-form-item>
        </el-form>
        <el-result v-if="active == 3" icon="success" title="更改成功">
          <template slot="extra">
            <el-button type="primary" @click="third" style="width: 100%">{{"返回登录 "+goBack+"s"}}</el-button>
          </template>
        </el-result>
      </el-card>
    </div>
  </div>
</template>

<script>

import headPageNew from "../wrongAuthenticatePage/headPageNew.vue";
import AES from "../../utils/aes";

import { setCookie,getCookie,delCookie,delAllStorage2 } from "@/utils/common";
  export default {
    components: {
      headPageNew,
    },
    data() {
      //手机号码校验
      const validateMobilePhone = (_, value, callback) => {
        if (value.trim() === "") {
          callback(new Error("请输入手机号码"));
        } else if (value && !this.checkReg(value, "mobile")) {
          callback(new Error("请输入正确的手机号码"));
        } else {
          callback();
        }
      }
      // 验证码校验
      const validateMsgCode = (_, value, callback) => {
        if (value.trim() === "") {
          callback(new Error("请输入验证码"));
        } else if (value && !this.checkReg(value, "msgCode")) {
          callback(new Error("验证码错误"));
        } else {
          callback();
        }
      }
      // 新密码校验
      const validatePass = (_, value, callback) => {
        if (value.trim() === "") {
          callback("请输入新密码");
        } else if (value && !this.checkReg(value, "passwordComplex")) {
          callback(new Error("请输入8-20位由字母、数字、特殊符号组成的密码"));
        } else {
          callback();
        }
      }
      // 两次密码是否一样
      const validateNewPass = (_, value, callback) => {
        if (
          this.ruleForm.passwordNew &&
          value.trim() != this.ruleForm.passwordNew.trim()
        ) {
          callback(new Error("请检查两次输入的新密码是否相同"));
        } else {
          callback();
        }
      }

      return {
        // 步骤位置
        active: 1,
        // 是否从忘记密码/更改密码所进
        forgetPasswordType: "",
        // 账号登录
        ruleForm: {
          mobilePhone: "", // 手机号
          msgCode: "", // 验证码
          passwordNew: "", // 新密码
          passwordOk: "", // 再次确认新密码
          token: "", // 临时token
        },
        // 表单规则
        rules: {
          mobilePhone: [{
            required: true, validator: validateMobilePhone, trigger: "blur"
          }],
          msgCode: [{
            required: true, validator: validateMsgCode, trigger: "blur"
          }],
          passwordNew: [{
            required: true, validator: validatePass, trigger: "blur"
          }],
          passwordOk: [{
            required: true, validator: validateNewPass, trigger: "blur"
          }]
        },
        // 倒计时
        totalTime: 180,
        countInterval: null,
        // 是否显示获取验证码按钮
        showSend: true,
        goBack: 5,
        goBackTimer: null
      }
    },
    created() {
      // 拿到忘记密码的类型
      this.forgetPasswordType = this.$route.query.forgetPasswordType;
      if (this.$route.query.forgetPasswordType == 2) {
        this.ruleForm.mobilePhone =
          JSON.parse(sessionStorage.getItem("userJson"))["mobile"] || "";
      }
    },
    beforeDestroy() {
      clearInterval(this.countInterval);
      clearInterval(this.goBackTimer);
    },
    methods: {
      checkReg(val, regName) {
        let reg = {
          userName: /^[a-zA-Z0-9_]{2,16}$/,
          password: /^[A-Za-z0-9]+$/,
          passwordComplex:/^(?!\d+$)(?![\/:@[`{~]+$)(?![a-zA-Z]+$)[0-9A-Za-z\/:@[`{~]{8,20}$/,
          msgCode:/^\d{6}$/,
          mobile: /^1\d{10}$/,
        };
        return reg[regName].test(val);
      },
      // 获取验证码
      getMsgCode() {
        this.$refs["ruleForm"].validateField("mobilePhone", (valid) => {
          if (!valid) {
            this.showSend = false;

            this.$post("/sys/sms/captcha", {
              mobile: this.ruleForm.mobilePhone,
            }, 3000, true,5)
              .then(() => {
                this.countDown();
                this.$message.success("验证码已发送到您的手机上!");
              });
          }
        });
      },
      // 倒计时
      countDown() {
        clearInterval(this.countInterval);
        this.countInterval = setInterval(() => {
          this.totalTime--;
          if (this.totalTime < 0) {
            clearInterval(this.countInterval);
            this.totalTime = 180;
            this.showSend = true;
          }
        }, 1000);
      },
      // 第一步获取验证码
      first() {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            this.$post("/sys/user/open/get-recovery-token", {
              mobile: this.ruleForm.mobilePhone,
              smsCode: this.ruleForm.msgCode,
            }, 3000, true,5)
              .then((res) => {
                // console.log(res.data)

                let data = AES.decrypt(res.data);
                this.ruleForm.token = data.token;
                this.active = 2;
              }).catch(() => {
                this.ruleForm.msgCode = "";
              });
          }
        });
      },
      // 第二步重置密码
      second() {
        // console.log(this.ruleForm.token)
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            this.$post("/sys/user/open/recover-password", {
              mobile: this.ruleForm.mobilePhone,
              token: this.ruleForm.token,
              password: this.ruleForm.passwordNew,
            }, 3000, true,5)
              .then((res) => {
                this.active = 3;
                this.$message.success(res.message + "5秒后自动返回登录");
                this.goBackTimer = setInterval(() => {
                  this.goBack--;
                  if (this.goBack <= 0) {
                    clearInterval(this.goBackTimer);
                    this.third();
                  }
                }, 1000);
              });
          }
        });
      },
      // 第三步修改成功，返回登录
      third() {
        this.$delCookie();
        this.$delAllStorage2();
        this.$router.push({
          path: "/web/login",
          query: {
            loginShowFlag: 'true',
            loginShowStu: 'true'
          }
        });
      },
      // 自动去除input中非数字
      filter(value, inputName) {
        value = value.replace(/\D/g, "");
        const {ruleForm} = this;
        if (ruleForm[inputName]) ruleForm[inputName] = value;
      },
      //   清除验证结果
      clearValid(val) {
        this.$refs["ruleForm"].clearValidate(val);
      }
    }
  }
</script>

<style lang="less" scoped>
    /deep/.el-button--primary{
      background-color: #2878ff;
      border-color: #2878ff;
    }
    /deep/.el-step__head.is-finish{
      color: #2878ff;
      border-color: #2878ff;
    }
    /deep/.el-step__title.is-finish{
      color: #2878ff;
    }
  .changeContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    .change_main {
      display: flex;
      width: 100%;
      max-width: 1200px;
      margin: calc(62px + 2rem) auto 2rem;
      flex-grow: 1;
      flex-basis: 0;
      .change_main_form {
        width: 500px;
        margin: auto;
        margin-top: 50px;
        .el-form-item {
          text-align: left;
        }
        .success {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 40px;
          div {
            color: #666666;
            text-align: center;
            font-size: 12px;
            margin-top: 20px;
          }
        }
        .button-group {
          .el-button {
            width: 250px;
          }
        }
      }
    }
  }

</style>
